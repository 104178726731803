import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import BlogTemplate from "../components/BlogTemplate"
import styled from "styled-components"
import Footer from "../components/Footer"
import NavigationBar from "../components/NavigationBar"
import Jumbotron from "../components/Jumbotron"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"

const header = "Home"
const pageSummary = "Welcome to our website."

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const ContentDiv = styled.div`
  flex-grow: 1;
  text-align: justify;
  text-justify: auto;
`

const IndexPage = ({ data }) => (
  <StyledContainer>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="The website of Lomond Roads Cycling Club."
      />
      <title>Lomond Roads Cycling Club - Home</title>
      <link rel="canonical" href="lomondroads.org/" />
    </Helmet>
    <NavigationBar />
    <Jumbotron header={header} pageSummary={pageSummary} />
    <ContentDiv>
      <Container>
        <Row>
          <Col>
            <p>
              Welcome to the Lomond Roads Cycling Club website. This site aims
              to provide an information and news resource for everything related
              to the club.
            </p>

            <p>
              If you are new to road cycling and would like to get involved with
              the club, then please <Link to="/contactUs">contact us</Link> for
              more information.
            </p>

            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Club members on ferry to Dunoon"
            />
            <div>
            <h1>News</h1>
            <BlogTemplate />
            </div>
          </Col>
        </Row>
      </Container>
    </ContentDiv>
    <Footer />
  </StyledContainer>
)

export default IndexPage

export const query = graphql`
  query MyQuery {
    file(relativePath: { eq: "clubMembers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90, webpQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
