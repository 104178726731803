import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  padding: 20px;
  margin: 10px;
`

const BlogTemplate = () => (
  <StaticQuery
    query={pageQuery}
    render={data => {
      const posts = data.allMarkdownRemark.edges

      return posts.map(({ node }) => {
        const title = node.frontmatter.title
        const content = node.internal.content
        const date = node.frontmatter.date
        return (
          <Container>
            <div>
              <h4>{title}</h4>
              <small>{date}</small>
              <p>{content}</p>
            </div>
          </Container>
        )
      })
    }}
  />
)

export default BlogTemplate

export const pageQuery = graphql`
  query blogPostComponentQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          headings {
            id
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
          internal {
            content
          }
        }
      }
    }
  }
`
